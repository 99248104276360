<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload" size="mini" type="primary" @click=" dialogVisible=true">
      上传
    </el-button>
    <el-dialog title="图片上传" :visible.sync="dialogVisible" append-to-body>
      <base-upload v-model="imgArr" :limit="5" :uploadData="uploadData" @beforeUpload="handleBeforeUpload" style="margin-bottom:50px;"></base-upload>
      <el-button @click="dialogVisible = false">
        取消
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        保存
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
// import { getToken } from 'api/qiniu'
import BaseUpload from "@/components/common/BaseUpload";
export default {
  name: 'EditorSlideUpload',
  components:{
	  BaseUpload
  },
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      imgArr:[],
      uploadData:{
				bizType:'',
				bizId:'',
				needStore:'0',
			},
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('successCBK', this.imgArr)
      this.dialogVisible = false
    },
    handleBeforeUpload(file, callback){
      const isPicture = file.name.includes('jpg') || file.name.includes('png');
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isPicture) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      callback(isPicture && isLt2M)
    },
  }
}
</script>